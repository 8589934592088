const Services = () => {
  return (
    <div>
      <h3 className="my-4 text-4xl text-center font-semibold italic">
        Services
      </h3>
      <div className="px-20 text-justify pb-10">
        <p>
          <strong>Ayurvedic Consultations</strong> involve a series of
          appointments in which the individual is evaluated and a personalized
          treatment plan is formulated. Over a period of several months,
          Ayurvedic lifestyle, diet, herbs and other treatments are gradually
          introduced and their effects are monitored.
        </p>
        <p className="my-4">
          The Goal of Ayurvedic consultation is to invite you to discover the
          wonderful science of Ayurveda and what it can do for you to create a
          healthy and peaceful life. You may simply be looking for more balance
          in you life or struggling with an existing condition, in either case
          we offer you guidance, support and education to bring harmony to your
          life. When there is harmony there is health and when there is
          disharmony there is disease. A general rule is that it takes six
          months to a year to restore balance depending on the length of
          imbalance and your ability to incorporate the plan into your
          lifestyle. Changes take time and our goal is to attain long term
          changes with benefits of a harmonious life.
        </p>
        <p className="my-4">
          <strong>The Initial Appointment</strong> involves a complete review of
          your health, history and present concerns, followed by a detailed
          assessment of your mind/body type and current state of balance. It
          lasts for 2 hours.
        </p>

        <p className="mb-4">
          <strong>Reports of Finding: </strong>
          This appointment occurs 2 or more days after the initial appointment
          and lasts an hour. Your individual constitution, imbalance and
          recommended healing plan is discussed at this time and education on
          Ayurvedic principles is provided. If appropriate, an individualized
          herbal formula is ordered. The cost of initial appointment and reports
          of finding is $145 and is due after the first appointment
        </p>

        <p className="mb-4">
          <strong>Follow-up Visits: </strong>
          Each visit takes approximately one hour. During these visits results
          of your treatment program are discussed and modification and/or
          additions are made as needed. The first two follow up visits are once
          a week and then every two weeks for a month or until the plan is
          completely integrated into your lifestyle. After that, monthly visits
          followed by seasonal /maintenance visits are recommended. The cost per
          visit is $95
        </p>
        <p className="my-4">
          <strong>Herbal Formulas: </strong>
          Fresh organic herbs are custom designed to meet your needs and bring
          balance into your life. Pricing is based on ingredients, shipping and
          the number of formulas needed. The cost of a standard formula is $16
          for 100 caps and $4.95 for shipping, due after the formula is
          received.
        </p>
      </div>
    </div>
  );
};
export default Services;
