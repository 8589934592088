const Testimonials = () => {
  return (
    <div>
      <h3 className="my-4 text-4xl text-center font-semibold italic">
        Testimonials
      </h3>
      <div className="px-20 text-justify pb-10">
        <p>
          Thanks so much for the wonderful sessions. Monika, you have taken me
          out of the deep dungeon of lethargy and depression which was really
          taking a toll on my life. I was in so much pain for almost three years
          and my doctor said it was nothing but depression and prescribed me
          anti-depressant medication. Then I thought of consulting you and that
          was the best decision I made. With your help my depression is gone, I
          feel better and have started to enjoy life once again and that too
          without all the horrible side effects of modern medicine.
          <br /> Thanks for turning my life around.
          <strong className="text-lime-600"> K.S. Fresno, CA.</strong>
        </p>
        <p className="my-3">
          The changes we've noticed in the yoga students who are practicing
          Ayurveda with you are astounding. I asked one woman today, do you feel
          as good as you look? Her answer was YES! We see that with your
          guidance and inspiration, you are helping people to live life in a
          happy, healthy body. Thank you for sharing your love and knowledge of
          Ayurvedic Medicine with us.
          <br />
          <strong className="text-lime-600">
            Michael and Melinda Downing, Directors of Yoga For Living, Fresno,
            CA .
          </strong>
        </p>
        <p className="my-3">
          I am a patient of Monika Joshi for almost 4 months. Before the
          ayurvedic treatment I had been taking a pain medication, prescribed by
          my doctor. The medicine was to be taken only for a short period of
          time, so I switched over to ayurvedic treatment. I am satisfied with
          Mrs. Joshi's way of treating patients as she attends to them nicely.
          She is very concerned for her patients and is always willing to help
          them, whether it is regarding consultation for a medicine or regarding
          a good and healthy diet. She was available whenever I wanted an
          appointment with her. I am happy with the ayurvedic treatment as it
          helps cure the illness with no side effects.
          <strong className="text-lime-600"> S.G. Fresno, CA .</strong>
        </p>
        <p className="my-3">
          I’ve only known Monika and the practice of Ayurveda for a few months
          now but the teachings have been life changing. I am learning to let go
          of the past, not dwell on the uncertainty of the future and most
          important to live in the moment! The techniques of meditation that I
          have learned have profoundly calmed and grounded me in dealing with my
          everyday life as well as brought my yoga practice to a whole new level
          of exhilaration and peacefulness. Through different food combinations
          and herbal supplements I’ve learned how sacred preparing and eating
          meals can be. My life has truly benefited. I consider her to be a
          wonderful gift and I look forward to my journey in Ayurveda.{" "}
          <strong className="text-lime-600">
            Rebecca L Pendleton, Fresno CA.
          </strong>
        </p>
        <p className="my-3">
          Following the recommendations of Monika Joshi has been the best health
          decision I have made in years. Incorporating only a few of her dietary
          suggestions has benefited me tremendously. I feel calmer and more
          settled, my food cravings have disappeared and I am losing weight
          without dieting. Ghee has been the best addition to my diet. Ghee
          feels very nourishing, and lubricating, especially to my dry throat.
          Ghee also satisfies my craving for fat and keeps me from being hungry
          between meals and it is very easy to make at home. Monika is the only
          practitioner that I know of who offers custom herb formulas. She knew
          exactly what I needed and herbs have helped me a lot.
          <strong className="text-lime-600"> M.D. - Austin, TX.</strong>
        </p>
      </div>
    </div>
  );
};
export default Testimonials;
